import React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { MaxWidthContainer } from "../../../components/styles/MaxWidthContainer";
import { SVGShapeHeader } from "../../../components/svgs";
import { theme } from "../../../styles/theme";

const StyledJumbotron = styled.section`
  position: relative;
  width: 100%;
  height: 330px;
  background: var(--orange-blue-gradient-left-bottom);

  .hero-image {
    width: 300px;
    height: auto;
    position: absolute;
    top: 18px;
    right: 130px;

    img {
      width: 100%;
      height: 100%;
    }

    @media (max-width: 1280px) {
      right: 170px;
    }

    @media (max-width: 1024px) {
      right: 188px;
    }

    @media (max-width: 768px) {
      width: 250px;
      top: 50px;
    }

    @media (max-width: 640px) {
      display: none;
    }
  }

  .svg-shape-container {
    width: 100%;
    overflow: hidden;
    display: block;
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
    width: 100%;
    height: 160px;
    transform: translateY(1px);
    color: var(--gray-1);

    svg {
      box-sizing: border-box;
      position: absolute;
      left: -3%;
      right: -3%;
      bottom: 0px;
      width: 106%;
    }
  }

  @media (${theme.bp.tabletL}) {
    background: #fe754b
      linear-gradient(0deg, rgba(19, 7, 55, 0.1) 0%, rgba(19, 7, 55, 0.5) 50%);
    height: 250px;
  }

  @media (${theme.bp.mobileL}) {
    background: #fe754b
      linear-gradient(0deg, rgba(19, 7, 55, 0.1) 0%, rgba(19, 7, 55, 0.5) 50%);
    height: 190px;

    svg {
      height: 60px;
    }
  }
`;

export const Jumbotron = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "hero.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <StyledJumbotron>
      <MaxWidthContainer padding={{ mobileS: 0, tabletL: "0 2rem" }}>
        <div className="hero-image">
          <Img
            fluid={data.placeholderImage.childImageSharp.fluid}
            alt="A guy looking at computer screen"
          />
        </div>
      </MaxWidthContainer>
      <div className="svg-shape-container">
        <SVGShapeHeader />
      </div>
    </StyledJumbotron>
  );
};
