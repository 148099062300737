import React from "react";
import styled, { keyframes } from "styled-components";
import { Link } from "gatsby";
import Box from "../../../components/Box";
import { MaxWidthContainer } from "../../../components/styles/MaxWidthContainer";
import { theme } from "../../../styles/theme";
import { SectionHeader } from "./SectionHeader";

const shiftAnimation = keyframes`
  0 {
    transform: translate(0px, 5px);
  }

  50% {
    transform: translate(10px, 5px);
  }

  100% {
    transform: translate(0px, 5px);
  }
`;

const StyledBlogs = styled.section`
  width: 100%;
  background: var(--gray-1);
  padding: 0 3rem;

  a {
    display: flex;
  }
`;

const PopularContent = styled.div`
  width: 66.66%;

  ul {
    list-style: none;

    li {
      display: flex;
      letter-spacing: 0.72px;
      &:not(:last-child) {
        margin-bottom: 16px;
      }

      svg {
        width: 16px;
        height: 16px;
        display: inline-block;
        margin-right: 12px;
        fill: var(--yellow);
        transform: translateY(5px);
      }

      &:hover {
        svg {
          animation: ${shiftAnimation} 0.5s linear infinite;
        }
      }
    }
  }

  @media (${theme.bp.mobileL}) {
    width: 100%;
  }
`;

const TopCategories = styled.div`
  width: 33.33%;
  padding-left: 110px;

  ul {
    display: flex;
    flex-wrap: wrap;

    li {
      padding: 6px 14px;
      background: var(--lightblue);
      color: var(--gray-2);
      border-radius: 8px;
      margin-bottom: 12px;
      font-size: 14px;
      transition: all 0.2s ease-in-out;

      &:not(:last-child) {
        margin-right: 12px;
      }

      &:hover {
        transform: scaleX(1.1);
        background: var(--darkblue);
      }
    }
  }

  @media (${theme.bp.tabletL}) {
    padding-left: 60px;
  }

  @media (${theme.bp.mobileL}) {
    width: 100%;
    padding-left: 0;
  }
`;

export const Blogs = ({ latestPosts }) => (
  <StyledBlogs>
    <Box height={{ mobileS: "44px", tabletS: "80px" }} />
    <MaxWidthContainer
      padding={{ mobileS: 0, tabletL: "0 2rem" }}
      width="100%"
      display="flex"
      flexWrap={{ mobileS: "wrap", tabletS: "nowrap" }}
    >
      <PopularContent>
        <SectionHeader>Latest Blogs</SectionHeader>

        <ul>
          {latestPosts.map(({ slug, title }) => (
            <li key={slug}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
              <Link to={`/blog/${slug}`}>{title}</Link>
            </li>
          ))}
        </ul>
      </PopularContent>

      <TopCategories>
        <Box height={{ mobileS: "44px", tabletS: 0 }} />
        <SectionHeader>Top Categories</SectionHeader>
        <ul>
          <li>React</li>
          <li>Javscript</li>
          <li>React Native</li>
          <li>GraphQL</li>
          <li>NodeJS</li>
        </ul>
        <Box height={{ mobileS: "44px", tabletS: 0 }} />
      </TopCategories>
    </MaxWidthContainer>
  </StyledBlogs>
);
