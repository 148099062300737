/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React from 'react';
import { graphql } from 'gatsby';

import { HomePage } from '../containers/homepage';

const IndexPage = ({ data }) => {
  const { edges } = data.allMarkdownRemark;

  const latestPosts = edges.map(edge => ({
    title: edge.node.frontmatter.title,
    slug: edge.node.fileAbsolutePath.split('/').pop().split('.')[0],
  }));

  return <HomePage latestPosts={latestPosts} />;
};

export default IndexPage;

export const homepageQuery = graphql`
  query homepageQuery {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/posts/" } }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 5
    ) {
      edges {
        node {
          fileAbsolutePath
          frontmatter {
            title
          }
        }
      }
    }
  }
`;
