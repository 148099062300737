import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Link } from 'gatsby';
import Box from '../../../components/Box';
import { MaxWidthContainer } from '../../../components/styles/MaxWidthContainer';
import { Title } from '../../../components/styles/Title';
import { theme } from '../../../styles/theme';
import { SectionHeader } from './SectionHeader';

const StyledIntro = styled.section`
  margin-top: -79px;
  z-index: 3;
  padding: 0 3rem;
  background: var(--gray-1);
  color: var(--primary-text);

  h1 {
    font-size: 2.25rem;
  }

  .container {
    display: flex;
  }

  @media (${theme.bp.mobileL}) {
    margin-top: 0;

    h1 {
      font-size: 54px;
    }

    .container {
      flex-wrap: wrap;
    }
  }
`;

const Introduction = styled.div`
  width: 66.666%;

  @media (${theme.bp.mobileL}) {
    width: 100%;
  }
`;
const IntroText = styled.p`
  font-size: 18px;
  letter-spacing: 0.8px;
`;

const Skills = styled.div`
  ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;

    li {
      flex-basis: 50%;
      display: flex;
      letter-spacing: 0.72px;
      &:not(:last-child) {
        margin-bottom: 16px;
      }
      svg {
        width: 16px;
        height: 16px;
        display: inline-block;
        margin-right: 12px;
        fill: var(--yellow);
        transform: translateY(5px);
      }
    }
  }
`;

const ListItem = ({ name }) => (
  <li>
    {/* prettier-ignore */}
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='currentColor'>
      <path fillRule='evenodd' d='M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z'  clipRule='evenodd' />
    </svg>
    {name}
  </li>
);

const Achievements = styled.div`
  width: 33.33%;
  padding-left: 110px;

  ul {
    list-style: none;
    margin-left: -24px;

    li {
      display: flex;
      &:not(:last-child) {
        margin-bottom: 16px;
      }
      svg {
        flex-shrink: 0;
        display: inline-block;
        width: 16px;
        height: 16px;
        margin-right: 12px;
        fill: var(--yellow);
        transform: translateY(5px);
      }
    }
  }

  @media (${theme.bp.tabletL}) {
    padding-left: 60px;
  }

  @media (${theme.bp.mobileL}) {
    width: 100%;
    padding-left: 0;

    ul {
      margin-left: 0;
    }
  }
`;

const SkillItem = ({ name }) => (
  <li>
    {/* prettier-ignore */}
    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8">
      <g id="Ellipse_5" data-name="Ellipse 5" fill="none" stroke="#fadf54" strokeWidth="1">
        <circle cx="4" cy="4" r="4" stroke="none"/>
        <circle cx="4" cy="4" r="3" fill="none"/>
      </g>
    </svg>
    {name}
  </li>
);

const projects = [
  { title: 'Quotic', href: 'https://quotic.vercel.app/' },
  { title: 'Execute.ly', href: 'https://executely.netlify.app/' },
  { title: 'UI/UX Designer Portfolio', href: 'https://ekanshi.netlify.app/' },
];

export const Intro = () => (
  <StyledIntro>
    <MaxWidthContainer padding={{ mobileS: 0, tabletL: '0 2rem' }}>
      <Title>Hello!</Title>
      <div className='container'>
        <Introduction>
          <IntroText>
            I&apos;m Ankit Tiwari, a software engineer passionate about learning and building things. I love solving
            problems using my computer science skills.
          </IntroText>
          <Box height='44px' />
          <Skills>
            <SectionHeader>Skills</SectionHeader>
            <ul>
              <SkillItem name='TypeScript' />
              <SkillItem name='JavaScript' />
              <SkillItem name='CSS' />
              <SkillItem name='HTML' />
              <SkillItem name='React' />
              <SkillItem name='Node' />
              <SkillItem name='GraphQL' />
              <SkillItem name='MongoDB' />
            </ul>
          </Skills>
        </Introduction>
        <Achievements>
          <Box height={{ mobileS: '44px', tabletS: 0 }} />
          <SectionHeader>Projects</SectionHeader>
          <ul>
            {projects.map(({ href, title }) => (
              <li key={title}>
                <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='currentColor'>
                  <path
                    fillRule='evenodd'
                    d='M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z'
                    clipRule='evenodd'
                  />
                </svg>
                <Link to={href}>{title}</Link>
              </li>
            ))}
          </ul>
        </Achievements>
      </div>
    </MaxWidthContainer>
  </StyledIntro>
);
