import React from "react";
import Box from "../../components/Box";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import { SocialIcons } from "./components/SocialIcons";
import { Jumbotron } from "./components/Jumbotron";
import { Intro } from "./components/Intro";
import { Blogs } from "./components/Blogs";

export const HomePage = ({ latestPosts }) => {
  return (
    <Layout>
      <SEO title="Home" />
      <Box bg="gray-1">
        <Jumbotron />
        <SocialIcons />
        <Intro />
        <Blogs latestPosts={latestPosts} />
      </Box>
    </Layout>
  );
};
