import React from "react";
import Box, { BoxProps } from "../Box";

type Props = BoxProps & { children: React.ReactNode };

export const MaxWidthContainer = ({ children, ...restProps }: Props) => (
  // @ts-expect-error
  <Box position="relative" maxWidth="1100px" px="2rem" mx="auto" {...restProps}>
    {children}
  </Box>
);
